@font-face {
  font-family: 'icomoon';
  src:  url('https://cdn.crediwatch.com/assets/stylesheets/cw-icons/fonts/icomoon.eot?77u4nd');
  src:  url('https://cdn.crediwatch.com/assets/stylesheets/cw-icons/fonts/icomoon.eot?77u4nd#iefix') format('embedded-opentype'),
    url('https://cdn.crediwatch.com/assets/stylesheets/cw-icons/fonts/icomoon.ttf?77u4nd') format('truetype'),
    url('https://cdn.crediwatch.com/assets/stylesheets/cw-icons/fonts/icomoon.woff?77u4nd') format('woff'),
    url('https://cdn.crediwatch.com/assets/stylesheets/cw-icons/fonts/icomoon.svg?77u4nd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 32px;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-default:before {
  font-size: 32px;
}
.icon-medium:before {
  font-size: 40px;
} 
.icon-large:before {
  font-size: 48px;
}
.icon-white:before {
  color: #fff !important;
}
.icon-about:before {
  content: "\e900";
  color: rgb(0, 61, 166);
}

.icon-advanced-filter:before {
  content: "\e901";
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path1:before {
  content: "\e902";
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path2:before {
  content: "\e903";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path3:before {
  content: "\e904";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path4:before {
  content: "\e905";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path5:before {
  content: "\e906";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path6:before {
  content: "\e907";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path7:before {
  content: "\e908";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path8:before {
  content: "\e909";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path9:before {
  content: "\e90a";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path10:before {
  content: "\e90b";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path11:before {
  content: "\e90c";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path12:before {
  content: "\e90d";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path13:before {
  content: "\e90e";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Alert-Case-Mapping .path14:before {
  content: "\e90f";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-alerts .path1:before {
  content: "\e910";
  color: rgb(0, 61, 166);
}
.icon-alerts .path2:before {
  content: "\e911";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path3:before {
  content: "\e912";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path4:before {
  content: "\e913";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path5:before {
  content: "\e914";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path6:before {
  content: "\e915";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path7:before {
  content: "\e916";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path8:before {
  content: "\e917";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path9:before {
  content: "\e918";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-alerts .path10:before {
  content: "\e919";
  margin-left: -0.9658203125em;
  color: rgb(0, 61, 166);
}
.icon-arrow-element:before {
  content: "\e91a";
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path1:before {
  content: "\e91b";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-artificial-juridical-person .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path3:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path4:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path5:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path6:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path7:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path8:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path9:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path10:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path11:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path12:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path13:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path14:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path15:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path16:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path17:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path18:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path19:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path20:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-artificial-juridical-person .path21:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path1:before {
  content: "\e930";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-association-of-persons .path2:before {
  content: "\e931";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path3:before {
  content: "\e932";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path4:before {
  content: "\e933";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path5:before {
  content: "\e934";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path6:before {
  content: "\e935";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path7:before {
  content: "\e936";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path8:before {
  content: "\e937";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path9:before {
  content: "\e938";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path10:before {
  content: "\e939";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path11:before {
  content: "\e93a";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path12:before {
  content: "\e93b";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path13:before {
  content: "\e93c";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path14:before {
  content: "\e93d";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path15:before {
  content: "\e93e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path16:before {
  content: "\e93f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path17:before {
  content: "\e940";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path18:before {
  content: "\e941";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path19:before {
  content: "\e942";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path20:before {
  content: "\e943";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-association-of-persons .path21:before {
  content: "\e944";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-blacklist:before {
  content: "\e945";
}
.icon-blacklists .path1:before {
  content: "\e946";
  color: rgb(0, 61, 166);
}
.icon-blacklists .path2:before {
  content: "\e947";
  margin-left: -1.111328125em;
  color: rgb(0, 61, 166);
}
.icon-blacklists .path3:before {
  content: "\e948";
  margin-left: -1.111328125em;
  color: rgb(0, 61, 166);
}
.icon-blacklists .path4:before {
  content: "\e949";
  margin-left: -1.111328125em;
  color: rgb(0, 61, 166);
}
.icon-blacklists .path5:before {
  content: "\e94a";
  margin-left: -1.111328125em;
  color: rgb(0, 61, 166);
}
.icon-blacklists .path6:before {
  content: "\e94b";
  margin-left: -1.111328125em;
  color: rgb(0, 61, 166);
}
.icon-blacklists .path7:before {
  content: "\e94c";
  margin-left: -1.111328125em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path1:before {
  content: "\e94d";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-body-of-individuals .path2:before {
  content: "\e94e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path3:before {
  content: "\e94f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path4:before {
  content: "\e950";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path5:before {
  content: "\e951";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path6:before {
  content: "\e952";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path7:before {
  content: "\e953";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path8:before {
  content: "\e954";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path9:before {
  content: "\e955";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path10:before {
  content: "\e956";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path11:before {
  content: "\e957";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path12:before {
  content: "\e958";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path13:before {
  content: "\e959";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path14:before {
  content: "\e95a";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path15:before {
  content: "\e95b";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path16:before {
  content: "\e95c";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path17:before {
  content: "\e95d";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path18:before {
  content: "\e95e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path19:before {
  content: "\e95f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path20:before {
  content: "\e960";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-body-of-individuals .path21:before {
  content: "\e961";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-business-details:before {
  content: "\e962";
}
.icon-bussiness-icon .path1:before {
  content: "\e963";
  color: rgb(0, 61, 166);
}
.icon-bussiness-icon .path2:before {
  content: "\e964";
  margin-left: -1.1689453125em;
  color: rgb(0, 61, 166);
}
.icon-bussiness-icon .path3:before {
  content: "\e965";
  margin-left: -1.1689453125em;
  color: rgb(0, 61, 166);
}
.icon-bussiness-icon .path4:before {
  content: "\e966";
  margin-left: -1.1689453125em;
  color: rgb(0, 61, 166);
}
.icon-bussiness-icon .path5:before {
  content: "\e967";
  margin-left: -1.1689453125em;
  color: rgb(0, 61, 166);
}
.icon-bussiness-icon .path6:before {
  content: "\e968";
  margin-left: -1.1689453125em;
  color: rgb(0, 61, 166);
}
.icon-bussiness-icon .path7:before {
  content: "\e969";
  margin-left: -1.1689453125em;
  color: rgb(0, 61, 166);
}
.icon-bussiness-icon .path8:before {
  content: "\e96a";
  margin-left: -1.1689453125em;
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-C-360:before {
  content: "\e96b";
  color: #003da6;
}
.icon-C-Docs:before {
  content: "\e96c";
  color: #003da6;
}
.icon-C-trust:before {
  content: "\e96d";
  color: #003da6;
}
.icon-Case-management .path1:before {
  content: "\e96e";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Case-management .path2:before {
  content: "\e96f";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Case-management .path3:before {
  content: "\e970";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Case-management .path4:before {
  content: "\e971";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Case-management .path5:before {
  content: "\e972";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Case-management .path6:before {
  content: "\e973";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-charges .path1:before {
  content: "\e974";
  color: rgb(0, 61, 166);
}
.icon-charges .path2:before {
  content: "\e975";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path3:before {
  content: "\e976";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path4:before {
  content: "\e977";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path5:before {
  content: "\e978";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path6:before {
  content: "\e979";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path7:before {
  content: "\e97a";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path8:before {
  content: "\e97b";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path9:before {
  content: "\e97c";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path10:before {
  content: "\e97d";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path11:before {
  content: "\e97e";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-charges .path12:before {
  content: "\e97f";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-check:before {
  content: "\e980";
  color: #afc653;
}
.icon-compilance-monitoring:before {
  content: "\e981";
  color: rgb(0, 61, 166);
}
.icon-contact .path1:before {
  content: "\e982";
  color: rgb(0, 61, 166);
}
.icon-contact .path2:before {
  content: "\e983";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-contact .path3:before {
  content: "\e984";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-contact .path4:before {
  content: "\e985";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-contact .path5:before {
  content: "\e986";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-contact .path6:before {
  content: "\e987";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-contacts:before {
  content: "\e988";
  color: #c7c7c7;
}
.icon-copyright:before {
  content: "\e989";
  color: rgb(0, 61, 166);
}
.icon-credit-ratings:before {
  content: "\e98a";
  color: rgb(0, 61, 166);
}
.icon-cross-icon:before {
  content: "\e98b";
  color: rgb(0, 61, 166);
}
.icon-default-report-icon:before {
  content: "\e98c";
  color: #003da6;
}
.icon-employee-trend:before {
  content: "\e98d";
}
.icon-epfo .path1:before {
  content: "\e98e";
  color: rgb(0, 61, 166);
}
.icon-epfo .path2:before {
  content: "\e98f";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path3:before {
  content: "\e990";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path4:before {
  content: "\e991";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path5:before {
  content: "\e992";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path6:before {
  content: "\e993";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path7:before {
  content: "\e994";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path8:before {
  content: "\e995";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path9:before {
  content: "\e996";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path10:before {
  content: "\e997";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path11:before {
  content: "\e998";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-epfo .path12:before {
  content: "\e999";
  margin-left: -1.037109375em;
  color: rgb(0, 61, 166);
}
.icon-ews .path1:before {
  content: "\e99a";
  color: rgb(0, 61, 166);
}
.icon-ews .path2:before {
  content: "\e99b";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path3:before {
  content: "\e99c";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path4:before {
  content: "\e99d";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path5:before {
  content: "\e99e";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path6:before {
  content: "\e99f";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path7:before {
  content: "\e9a0";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path8:before {
  content: "\e9a1";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path9:before {
  content: "\e9a2";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path10:before {
  content: "\e9a3";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path11:before {
  content: "\e9a4";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-ews .path12:before {
  content: "\e9a5";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-facebook:before {
  content: "\e9a6";
  color: rgb(0, 61, 166);
}
.icon-feature-icon:before {
  content: "\e9a7";
  color: rgb(0, 61, 166);
}
.icon-Feature-Weights .path1:before {
  content: "\e9a8";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Feature-Weights .path2:before {
  content: "\e9a9";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Feature-Weights .path3:before {
  content: "\e9aa";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Feature-Weights .path4:before {
  content: "\e9ab";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Feature-Weights .path5:before {
  content: "\e9ac";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Feature-Weights .path6:before {
  content: "\e9ad";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Feature-Weights .path7:before {
  content: "\e9ae";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Financials:before {
  content: "\e9af";
  color: rgb(0, 61, 166);
}
.icon-financials2 .path1:before {
  content: "\e9b0";
  color: rgb(0, 61, 166);
}
.icon-financials2 .path2:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-financials2 .path3:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-financials2 .path4:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-financials2 .path5:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-financials2 .path6:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-financials2 .path7:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-firm .path1:before {
  content: "\e9b7";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-firm .path2:before {
  content: "\e9b8";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path3:before {
  content: "\e9b9";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path4:before {
  content: "\e9ba";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path5:before {
  content: "\e9bb";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path6:before {
  content: "\e9bc";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path7:before {
  content: "\e9bd";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path8:before {
  content: "\e9be";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path9:before {
  content: "\e9bf";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path10:before {
  content: "\e9c0";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path11:before {
  content: "\e9c1";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path12:before {
  content: "\e9c2";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path13:before {
  content: "\e9c3";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path14:before {
  content: "\e9c4";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path15:before {
  content: "\e9c5";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path16:before {
  content: "\e9c6";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-firm .path17:before {
  content: "\e9c7";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-footer-logo .path1:before {
  content: "\e9c8";
  color: rgb(0, 0, 0);
}
.icon-footer-logo .path2:before {
  content: "\e9c9";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path3:before {
  content: "\e9ca";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path4:before {
  content: "\e9cb";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path5:before {
  content: "\e9cc";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path6:before {
  content: "\e9cd";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path7:before {
  content: "\e9ce";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path8:before {
  content: "\e9cf";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path9:before {
  content: "\e9d0";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path10:before {
  content: "\e9d1";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path11:before {
  content: "\e9d2";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path12:before {
  content: "\e9d3";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path13:before {
  content: "\e9d4";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path14:before {
  content: "\e9d5";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path15:before {
  content: "\e9d6";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-footer-logo .path16:before {
  content: "\e9d7";
  margin-left: -4.34765625em;
  color: rgb(0, 61, 166);
  opacity: 0.5;
}
.icon-government .path1:before {
  content: "\e9d8";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-government .path2:before {
  content: "\e9d9";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-government .path3:before {
  content: "\e9da";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-government .path4:before {
  content: "\e9db";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-government .path5:before {
  content: "\e9dc";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Group-17553 .path1:before {
  content: "\e9dd";
  color: rgb(227, 238, 255);
}
.icon-Group-17553 .path2:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17553 .path3:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17553 .path4:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17553 .path5:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17553 .path6:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17555 .path1:before {
  content: "\e9e3";
  color: rgb(227, 238, 255);
}
.icon-Group-17555 .path2:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17555 .path3:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17555 .path4:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17555 .path5:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17555 .path6:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17555 .path7:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17555 .path8:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17660 .path1:before {
  content: "\e9eb";
  color: rgb(227, 238, 255);
}
.icon-Group-17660 .path2:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17660 .path3:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17660 .path4:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17660 .path5:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17660 .path6:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Group-17660 .path7:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-Groups .path1:before {
  content: "\e9f2";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Groups .path2:before {
  content: "\e9f3";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Groups .path3:before {
  content: "\e9f4";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Groups .path4:before {
  content: "\e9f5";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Groups .path5:before {
  content: "\e9f6";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path1:before {
  content: "\e9f7";
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path3:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path4:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path5:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path6:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path7:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path8:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path9:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path10:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path11:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path12:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path13:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path14:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path15:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-gstn-info .path16:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ham-menu:before {
  content: "\ea07";
  color: #4f5d7b;
}
.icon-highlights .path1:before {
  content: "\ea08";
  color: rgb(0, 61, 166);
}
.icon-highlights .path2:before {
  content: "\ea09";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path3:before {
  content: "\ea0a";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path4:before {
  content: "\ea0b";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path5:before {
  content: "\ea0c";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path6:before {
  content: "\ea0d";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path7:before {
  content: "\ea0e";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path8:before {
  content: "\ea0f";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path9:before {
  content: "\ea10";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path10:before {
  content: "\ea11";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path11:before {
  content: "\ea12";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path12:before {
  content: "\ea13";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-highlights .path13:before {
  content: "\ea14";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path1:before {
  content: "\ea15";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-hindu-undivided-family .path2:before {
  content: "\ea16";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path3:before {
  content: "\ea17";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path4:before {
  content: "\ea18";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path5:before {
  content: "\ea19";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path6:before {
  content: "\ea1a";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path7:before {
  content: "\ea1b";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path8:before {
  content: "\ea1c";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path9:before {
  content: "\ea1d";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path10:before {
  content: "\ea1e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path11:before {
  content: "\ea1f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path12:before {
  content: "\ea20";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path13:before {
  content: "\ea21";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path14:before {
  content: "\ea22";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path15:before {
  content: "\ea23";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path16:before {
  content: "\ea24";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path17:before {
  content: "\ea25";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path18:before {
  content: "\ea26";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path19:before {
  content: "\ea27";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path20:before {
  content: "\ea28";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-hindu-undivided-family .path21:before {
  content: "\ea29";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-information:before {
  content: "\ea2a";
  color: #c7c7c7;
}
.icon-instagram:before {
  content: "\ea2b";
  color: rgb(0, 61, 166);
}
.icon-integrations-icon:before {
  content: "\ea2c";
  color: #4f5d7b;
}
.icon-Jobs .path1:before {
  content: "\ea2d";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Jobs .path2:before {
  content: "\ea2e";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path3:before {
  content: "\ea2f";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path4:before {
  content: "\ea30";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path5:before {
  content: "\ea31";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path6:before {
  content: "\ea32";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path7:before {
  content: "\ea33";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path8:before {
  content: "\ea34";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path9:before {
  content: "\ea35";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path10:before {
  content: "\ea36";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path11:before {
  content: "\ea37";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path12:before {
  content: "\ea38";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path13:before {
  content: "\ea39";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path14:before {
  content: "\ea3a";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path15:before {
  content: "\ea3b";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path16:before {
  content: "\ea3c";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Jobs .path17:before {
  content: "\ea3d";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-key .path1:before {
  content: "\ea3e";
  color: rgb(0, 61, 166);
}
.icon-key .path2:before {
  content: "\ea3f";
  margin-left: -1.03125em;
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-legal .path1:before {
  content: "\ea40";
  color: rgb(0, 61, 166);
}
.icon-legal .path2:before {
  content: "\ea41";
  margin-left: -0.9306640625em;
  color: rgb(0, 61, 166);
}
.icon-legal .path3:before {
  content: "\ea42";
  margin-left: -0.9306640625em;
  color: rgb(0, 61, 166);
}
.icon-legal .path4:before {
  content: "\ea43";
  margin-left: -0.9306640625em;
  color: rgb(0, 61, 166);
}
.icon-legal .path5:before {
  content: "\ea44";
  margin-left: -0.9306640625em;
  color: rgb(0, 61, 166);
}
.icon-legal .path6:before {
  content: "\ea45";
  margin-left: -0.9306640625em;
  color: rgb(0, 61, 166);
}
.icon-legal .path7:before {
  content: "\ea46";
  margin-left: -0.9306640625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path1:before {
  content: "\ea47";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-limited-liabilit-partnership .path2:before {
  content: "\ea48";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path3:before {
  content: "\ea49";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path4:before {
  content: "\ea4a";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path5:before {
  content: "\ea4b";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path6:before {
  content: "\ea4c";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path7:before {
  content: "\ea4d";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path8:before {
  content: "\ea4e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path9:before {
  content: "\ea4f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path10:before {
  content: "\ea50";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path11:before {
  content: "\ea51";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path12:before {
  content: "\ea52";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path13:before {
  content: "\ea53";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path14:before {
  content: "\ea54";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-limited-liabilit-partnership .path15:before {
  content: "\ea55";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-linkedin:before {
  content: "\ea56";
  color: rgb(0, 61, 166);
}
.icon-litigation-check:before {
  content: "\ea57";
}
.icon-local-authority .path1:before {
  content: "\ea58";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-local-authority .path2:before {
  content: "\ea59";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path3:before {
  content: "\ea5a";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path4:before {
  content: "\ea5b";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path5:before {
  content: "\ea5c";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path6:before {
  content: "\ea5d";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path7:before {
  content: "\ea5e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path8:before {
  content: "\ea5f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path9:before {
  content: "\ea60";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path10:before {
  content: "\ea61";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path11:before {
  content: "\ea62";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path12:before {
  content: "\ea63";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path13:before {
  content: "\ea64";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path14:before {
  content: "\ea65";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path15:before {
  content: "\ea66";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-local-authority .path16:before {
  content: "\ea67";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-media-sentiments:before {
  content: "\ea68";
  color: rgb(0, 61, 166);
}
.icon-ml .path1:before {
  content: "\ea69";
  color: rgb(0, 61, 166);
}
.icon-ml .path2:before {
  content: "\ea6a";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path3:before {
  content: "\ea6b";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path4:before {
  content: "\ea6c";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path5:before {
  content: "\ea6d";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path6:before {
  content: "\ea6e";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path7:before {
  content: "\ea6f";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path8:before {
  content: "\ea70";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path9:before {
  content: "\ea71";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path10:before {
  content: "\ea72";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path11:before {
  content: "\ea73";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path12:before {
  content: "\ea74";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path13:before {
  content: "\ea75";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path14:before {
  content: "\ea76";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path15:before {
  content: "\ea77";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path16:before {
  content: "\ea78";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path17:before {
  content: "\ea79";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path18:before {
  content: "\ea7a";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path19:before {
  content: "\ea7b";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path20:before {
  content: "\ea7c";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path21:before {
  content: "\ea7d";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path22:before {
  content: "\ea7e";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path23:before {
  content: "\ea7f";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path24:before {
  content: "\ea80";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
}
.icon-ml .path25:before {
  content: "\ea81";
  margin-left: -1.1376953125em;
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-news .path1:before {
  content: "\ea82";
  color: rgb(0, 61, 166);
}
.icon-news .path2:before {
  content: "\ea83";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path3:before {
  content: "\ea84";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path4:before {
  content: "\ea85";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path5:before {
  content: "\ea86";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path6:before {
  content: "\ea87";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path7:before {
  content: "\ea88";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path8:before {
  content: "\ea89";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path9:before {
  content: "\ea8a";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path10:before {
  content: "\ea8b";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path11:before {
  content: "\ea8c";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path12:before {
  content: "\ea8d";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-news .path13:before {
  content: "\ea8e";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-notification:before {
  content: "\ea8f";
  color: #4f5d7b;
}
.icon-one-person-company .path1:before {
  content: "\ea90";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-one-person-company .path2:before {
  content: "\ea91";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path3:before {
  content: "\ea92";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path4:before {
  content: "\ea93";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path5:before {
  content: "\ea94";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path6:before {
  content: "\ea95";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path7:before {
  content: "\ea96";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path8:before {
  content: "\ea97";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path9:before {
  content: "\ea98";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path10:before {
  content: "\ea99";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path11:before {
  content: "\ea9a";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path12:before {
  content: "\ea9b";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path13:before {
  content: "\ea9c";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path14:before {
  content: "\ea9d";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path15:before {
  content: "\ea9e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path16:before {
  content: "\ea9f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path17:before {
  content: "\eaa0";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path18:before {
  content: "\eaa1";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path19:before {
  content: "\eaa2";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path20:before {
  content: "\eaa3";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-one-person-company .path21:before {
  content: "\eaa4";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-Organisation-Profile .path1:before {
  content: "\eaa5";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Organisation-Profile .path2:before {
  content: "\eaa6";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-Organisation-Units .path1:before {
  content: "\eaa7";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Organisation-Units .path2:before {
  content: "\eaa8";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-Parties .path1:before {
  content: "\eaa9";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Parties .path2:before {
  content: "\eaaa";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Parties .path3:before {
  content: "\eaab";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Parties .path4:before {
  content: "\eaac";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Parties .path5:before {
  content: "\eaad";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Policy .path1:before {
  content: "\eaae";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Policy .path2:before {
  content: "\eaaf";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Policy .path3:before {
  content: "\eab0";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Policy .path4:before {
  content: "\eab1";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Policy .path5:before {
  content: "\eab2";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Policy .path6:before {
  content: "\eab3";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Policy .path7:before {
  content: "\eab4";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-Policy .path8:before {
  content: "\eab5";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-porfile:before {
  content: "\eab6";
  color: #4f5d7b;
}
.icon-Portfolios .path1:before {
  content: "\eab7";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Portfolios .path2:before {
  content: "\eab8";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-Portfolios .path3:before {
  content: "\eab9";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-Portfolios .path4:before {
  content: "\eaba";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-Portfolios .path5:before {
  content: "\eabb";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-Portfolios .path6:before {
  content: "\eabc";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-Portfolios .path7:before {
  content: "\eabd";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-Portfolios .path8:before {
  content: "\eabe";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-Portfolios .path9:before {
  content: "\eabf";
  margin-left: -1.3330078125em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path1:before {
  content: "\eac0";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-private-company .path2:before {
  content: "\eac1";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path3:before {
  content: "\eac2";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path4:before {
  content: "\eac3";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path5:before {
  content: "\eac4";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path6:before {
  content: "\eac5";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path7:before {
  content: "\eac6";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path8:before {
  content: "\eac7";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path9:before {
  content: "\eac8";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path10:before {
  content: "\eac9";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path11:before {
  content: "\eaca";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path12:before {
  content: "\eacb";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path13:before {
  content: "\eacc";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path14:before {
  content: "\eacd";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-private-company .path15:before {
  content: "\eace";
  margin-left: -1.06640625em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path1:before {
  content: "\eacf";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-proprietorship .path2:before {
  content: "\ead0";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path3:before {
  content: "\ead1";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path4:before {
  content: "\ead2";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path5:before {
  content: "\ead3";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path6:before {
  content: "\ead4";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path7:before {
  content: "\ead5";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path8:before {
  content: "\ead6";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-proprietorship .path9:before {
  content: "\ead7";
  margin-left: -1.23046875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path1:before {
  content: "\ead8";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-public-company .path2:before {
  content: "\ead9";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path3:before {
  content: "\eada";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path4:before {
  content: "\eadb";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path5:before {
  content: "\eadc";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path6:before {
  content: "\eadd";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path7:before {
  content: "\eade";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path8:before {
  content: "\eadf";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path9:before {
  content: "\eae0";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path10:before {
  content: "\eae1";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path11:before {
  content: "\eae2";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path12:before {
  content: "\eae3";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path13:before {
  content: "\eae4";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path14:before {
  content: "\eae5";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path15:before {
  content: "\eae6";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path16:before {
  content: "\eae7";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path17:before {
  content: "\eae8";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path18:before {
  content: "\eae9";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path19:before {
  content: "\eaea";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path20:before {
  content: "\eaeb";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path21:before {
  content: "\eaec";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path22:before {
  content: "\eaed";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path23:before {
  content: "\eaee";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-company .path24:before {
  content: "\eaef";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path1:before {
  content: "\eaf0";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-public-listed-company .path2:before {
  content: "\eaf1";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path3:before {
  content: "\eaf2";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path4:before {
  content: "\eaf3";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path5:before {
  content: "\eaf4";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path6:before {
  content: "\eaf5";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path7:before {
  content: "\eaf6";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path8:before {
  content: "\eaf7";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path9:before {
  content: "\eaf8";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path10:before {
  content: "\eaf9";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path11:before {
  content: "\eafa";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path12:before {
  content: "\eafb";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path13:before {
  content: "\eafc";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path14:before {
  content: "\eafd";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path15:before {
  content: "\eafe";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path16:before {
  content: "\eaff";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path17:before {
  content: "\eb00";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path18:before {
  content: "\eb01";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path19:before {
  content: "\eb02";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path20:before {
  content: "\eb03";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path21:before {
  content: "\eb04";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path22:before {
  content: "\eb05";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path23:before {
  content: "\eb06";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-listed-company .path24:before {
  content: "\eb07";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path1:before {
  content: "\eb08";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-public-unlisted-company .path2:before {
  content: "\eb09";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path3:before {
  content: "\eb0a";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path4:before {
  content: "\eb0b";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path5:before {
  content: "\eb0c";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path6:before {
  content: "\eb0d";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path7:before {
  content: "\eb0e";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path8:before {
  content: "\eb0f";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path9:before {
  content: "\eb10";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path10:before {
  content: "\eb11";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path11:before {
  content: "\eb12";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path12:before {
  content: "\eb13";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path13:before {
  content: "\eb14";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path14:before {
  content: "\eb15";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path15:before {
  content: "\eb16";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path16:before {
  content: "\eb17";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path17:before {
  content: "\eb18";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path18:before {
  content: "\eb19";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path19:before {
  content: "\eb1a";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path20:before {
  content: "\eb1b";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path21:before {
  content: "\eb1c";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path22:before {
  content: "\eb1d";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path23:before {
  content: "\eb1e";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-public-unlisted-company .path24:before {
  content: "\eb1f";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path1:before {
  content: "\eb20";
  color: rgb(0, 61, 166);
}
.icon-ratings .path2:before {
  content: "\eb21";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path3:before {
  content: "\eb22";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path4:before {
  content: "\eb23";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path5:before {
  content: "\eb24";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path6:before {
  content: "\eb25";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path7:before {
  content: "\eb26";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path8:before {
  content: "\eb27";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path9:before {
  content: "\eb28";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path10:before {
  content: "\eb29";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-ratings .path11:before {
  content: "\eb2a";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-registration:before {
  content: "\eb2b";
  color: #c7c7c7;
}
.icon-registrations .path1:before {
  content: "\eb2c";
  color: rgb(0, 61, 166);
}
.icon-registrations .path2:before {
  content: "\eb2d";
  margin-left: -0.966796875em;
  color: rgb(0, 61, 166);
}
.icon-registrations .path3:before {
  content: "\eb2e";
  margin-left: -0.966796875em;
  color: rgb(0, 61, 166);
}
.icon-registrations .path4:before {
  content: "\eb2f";
  margin-left: -0.966796875em;
  color: rgb(0, 61, 166);
}
.icon-registrations .path5:before {
  content: "\eb30";
  margin-left: -0.966796875em;
  color: rgb(0, 61, 166);
}
.icon-registrations .path6:before {
  content: "\eb31";
  margin-left: -0.966796875em;
  color: rgb(0, 61, 166);
}
.icon-registrations .path7:before {
  content: "\eb32";
  margin-left: -0.966796875em;
  color: rgb(0, 61, 166);
}
.icon-registrations .path8:before {
  content: "\eb33";
  margin-left: -0.966796875em;
  color: rgb(0, 61, 166);
}
.icon-relationship:before {
  content: "\eb34";
}
.icon-relationships .path1:before {
  content: "\eb35";
  color: rgb(0, 61, 166);
}
.icon-relationships .path2:before {
  content: "\eb36";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path3:before {
  content: "\eb37";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path4:before {
  content: "\eb38";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path5:before {
  content: "\eb39";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path6:before {
  content: "\eb3a";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path7:before {
  content: "\eb3b";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path8:before {
  content: "\eb3c";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path9:before {
  content: "\eb3d";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-relationships .path10:before {
  content: "\eb3e";
  margin-left: -1.0341796875em;
  color: rgb(0, 61, 166);
}
.icon-report-list:before {
  content: "\eb3f";
  color: #003da6;
}
.icon-reports-icon:before {
  content: "\eb40";
  color: #4f5d7b;
}
.icon-Roles .path1:before {
  content: "\eb41";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Roles .path2:before {
  content: "\eb42";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Roles .path3:before {
  content: "\eb43";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Roles .path4:before {
  content: "\eb44";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Roles .path5:before {
  content: "\eb45";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-Roles .path6:before {
  content: "\eb46";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-sanctions:before {
  content: "\eb47";
}
.icon-sanctions2 .path1:before {
  content: "\eb48";
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path2:before {
  content: "\eb49";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path3:before {
  content: "\eb4a";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path4:before {
  content: "\eb4b";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path5:before {
  content: "\eb4c";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path6:before {
  content: "\eb4d";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path7:before {
  content: "\eb4e";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path8:before {
  content: "\eb4f";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path9:before {
  content: "\eb50";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path10:before {
  content: "\eb51";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-sanctions2 .path11:before {
  content: "\eb52";
  margin-left: -1.07421875em;
  color: rgb(0, 61, 166);
}
.icon-score .path1:before {
  content: "\eb53";
  color: rgb(0, 61, 166);
}
.icon-score .path2:before {
  content: "\eb54";
  margin-left: -1.22265625em;
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-scrolldown:before {
  content: "\eb55";
  color: rgb(0, 61, 166);
}
.icon-Signal-Master .path1:before {
  content: "\eb56";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Signal-Master .path2:before {
  content: "\eb57";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Signal-Master .path3:before {
  content: "\eb58";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Signal-Master .path4:before {
  content: "\eb59";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Signal-Master .path5:before {
  content: "\eb5a";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-Signal-Master .path6:before {
  content: "\eb5b";
  margin-left: -1.185546875em;
  color: rgb(0, 61, 166);
}
.icon-subscribe .path1:before {
  content: "\eb5c";
  color: rgb(227, 238, 255);
}
.icon-subscribe .path2:before {
  content: "\eb5d";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-support:before {
  content: "\eb5e";
  color: #4f5d7b;
}
.icon-timeline .path1:before {
  content: "\eb5f";
  color: rgb(0, 61, 166);
}
.icon-timeline .path2:before {
  content: "\eb60";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path3:before {
  content: "\eb61";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path4:before {
  content: "\eb62";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path5:before {
  content: "\eb63";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path6:before {
  content: "\eb64";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path7:before {
  content: "\eb65";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path8:before {
  content: "\eb66";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path9:before {
  content: "\eb67";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path10:before {
  content: "\eb68";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path11:before {
  content: "\eb69";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path12:before {
  content: "\eb6a";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path13:before {
  content: "\eb6b";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-timeline .path14:before {
  content: "\eb6c";
  margin-left: -1em;
  color: rgb(0, 61, 166);
}
.icon-trust .path1:before {
  content: "\eb6d";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-trust .path2:before {
  content: "\eb6e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path3:before {
  content: "\eb6f";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path4:before {
  content: "\eb70";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path5:before {
  content: "\eb71";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path6:before {
  content: "\eb72";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path7:before {
  content: "\eb73";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path8:before {
  content: "\eb74";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path9:before {
  content: "\eb75";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path10:before {
  content: "\eb76";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path11:before {
  content: "\eb77";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path12:before {
  content: "\eb78";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path13:before {
  content: "\eb79";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path14:before {
  content: "\eb7a";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path15:before {
  content: "\eb7b";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path16:before {
  content: "\eb7c";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path17:before {
  content: "\eb7d";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-trust .path18:before {
  content: "\eb7e";
  margin-left: -1.0322265625em;
  color: rgb(0, 61, 166);
}
.icon-twitter:before {
  content: "\eb7f";
  color: rgb(0, 61, 166);
}
.icon-un-watchlist:before {
  content: "\eb80";
  color: #4f5d7b;
}
.icon-unlock-icon:before {
  content: "\eb81";
}
.icon-Unwatchlist:before {
  content: "\eb82";
  color: #003da6;
}
.icon-Users .path1:before {
  content: "\eb83";
  color: rgb(0, 61, 166);
  opacity: 0.18;
}
.icon-Users .path2:before {
  content: "\eb84";
  margin-left: -1.142578125em;
  color: rgb(0, 61, 166);
}
.icon-verify-business:before {
  content: "\eb85";
  color: #4f5d7b;
}
.icon-voucher .path1:before {
  content: "\eb86";
  color: rgb(32, 81, 196);
}
.icon-voucher .path2:before {
  content: "\eb87";
  margin-left: -1.0810546875em;
  color: rgb(246, 200, 84);
  opacity: 0.18;
}
.icon-voucher .path3:before {
  content: "\eb88";
  margin-left: -1.0810546875em;
  color: rgb(0, 0, 0);
}
.icon-voucher .path4:before {
  content: "\eb89";
  margin-left: -1.0810546875em;
  color: rgb(66, 141, 255);
}
.icon-voucher .path5:before {
  content: "\eb8a";
  margin-left: -1.0810546875em;
  color: rgb(66, 141, 255);
}
.icon-voucher .path6:before {
  content: "\eb8b";
  margin-left: -1.0810546875em;
  color: rgb(66, 141, 255);
}
.icon-voucher .path7:before {
  content: "\eb8c";
  margin-left: -1.0810546875em;
  color: rgb(66, 141, 255);
}
.icon-watchlist-icon:before {
  content: "\eb8d";
  color: #4f5d7b;
}
.icon-watchlist:before {
  content: "\eb8e";
  color: #003da6;
}
.icon-youtube:before {
  content: "\eb8f";
  color: rgb(0, 61, 166);
}
