.userBox-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 371px;
  padding: 0px;
  margin: 3px 0 0;
  margin-right: 40px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #F8F9FA;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.userBox-Card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 14, 39, 0.125);
  margin: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.userBox-col-sm-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%
}

.userBox-col-sm-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%
}

.userBox-widget-content {
  padding: 1rem;
  flex-direction: row;
  align-items: center
}

@media (max-width:768px){
  .userBox-dropdown{
  margin-right:17px;
  left:30%;
  transform: translateX(-50%);
  }
  }

.userBox-widget-content .userBox-Widget-Content-Wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.userBox-Heading {
  font-family: 'Lato', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;
  font-size: 16px;
  font-weight:500;
  line-height: 19.2px;
  color: #313948;
}

.userBox-subHeading {
  font-family: 'Lato', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight:400;
  line-height: 16.8px;
  color: #ADB5BD;
}
@media (min-width: 769px) {
.UserBox-LastContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}
}
@media (max-width: 768px) {
  .UserBox-LastContainer{
    display:none;
  }
}

@media (max-width:768px){
  .Mobile-signout{
    display:block;
  }
}
.Mobile-signout{
  display:none;
}
.UserBox-LastContainerSubHeading {
  font-family: 'Lato', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;
  color: #6C757D;
  font-weight: 500;
  font-size:12px;
  line-height:14.4px
}

@media (max-width:768px){
.userBox-signout {
    display:flex;
    justify-content:center;
    align-items:center;
}
}
@media (min-width:769px){
.userBox-signout{
  display:none;
}
}
.UserBox-AnchorName {
  font-family: 'Lato', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px; 
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #15181E;
}

/* Styles for small devices */
@media (max-width: 768px) {
  .UserBox-AnchorName {
    font-size: 12px; 
  }
}

.sign-out-primary {
  content: url('../../../../styleSheets/icons/signoutprimary.svg');
}

.Header-dropdown{
  content: url('../../../../styleSheets/icons/Header-dropdown.svg');
}
.Support-icon{
  content: url('../../../../styleSheets/icons/Support-icon.svg');
}