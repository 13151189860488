// Header Base

.app-header {
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all .2s;

  &.header-shadow {
    box-shadow: $box-shadow-sm;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 $layout-spacer-x;
    height: $app-header-height;

    .app-header-left {
      display: flex;
      align-items: center;
    }

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
  }

  .header-user-box {
    border: $card-border-width solid $card-border-color;
    padding: 2px 4px;
    border-radius: $btn-border-radius!important;
  }

  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }

  .header-org-logo {
    padding: 0 4px 0 1px  ;
  }
}

.app-header__logo {
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  align-items: center;
  transition: width .2s;

  .logo-src {
    height: $logo-height;
    width: $logo-width;
    background: $logo_crediwatch no-repeat;
    background-size: contain;
  }

  .logo-admin {
    background: $logo_admin no-repeat;
  }
  .logo-cmm {
    background: $logo_cmm no-repeat;
  }
  .logo-accounts {
    background: $logo_accounts no-repeat;
  }
  .logo-developer {
    background: $logo_developer no-repeat;
  }
  .logo-my-business {
    background: $logo_my_business no-repeat;
  }
  .logo-mis {
    background: $logo_mis no-repeat;
  }
  .logo-jobs {
    background: $logo_jobs no-repeat;
  }
  .logo-reporting {
    background: $logo_reporting no-repeat;
  }
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  align-items: center;
}

// Header Modifiers

@import "modifiers/fixed-header";
@import "modifiers/header-dots";
@import "modifiers/header-megamenu";
@import "modifiers/header-buttons";
//@import "modifiers/header-horizontal";

// Header Themes

@import "themes/header-light";
@import "themes/header-dark";
//@import "themes/header-inverted";