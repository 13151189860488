@import url($fc-family);

tr {
  page-break-inside: avoid;
}

.pdf_title {
  color: #313948;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  text-align: center;
  padding: 10px;
  display: block;
}

.pdf_heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #3e485c;
  padding: 2px;
  margin-top: 25px;
}

.pdf_table_data {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 0.02em;
  background: white;
}

.pdf_table_heading {
  background: #f9fafd;
  height: 30px;
  text-align: left;
}

.pdf_table_heading th {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 16px !important;
  color: #818bad !important;
  border: none !important;
  text-transform: uppercase;
}

.financial_factors_table_font_size {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.pdf_padding {
  padding: 12px;
}

/* .pdf_table_data:nth-of-type(odd) {
  background-color: #f9f9f9;
} */

.pdf_table_units {
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: #000000;
}

/* ********************Card styles******************* */
.pdf_card_heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #818bad;
  text-transform: uppercase;
}

.pdf_card_data {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #333a41;
}

.pdf_card_time {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7d7c7c;
}

.pdf_card_key {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #003da6;
}

.pdf_basic_info_data:nth-of-type(odd) {
  background-color: #f9f9f9;
}

/********************* Front page designs ********************/
.right_box {
  position: relative;
  margin-left: 390px;
}

.right_box_content {
  position: absolute;
  left: 20px;
  top: 260px;
}

.left_box {
  position: absolute;
  z-index: 2;
  top: 130px;
}

.left_box_content {
  text-align: right;
  height: 100%;
  padding: 25px 25px;
  font-size: 16px;
  color: #003da6;
}

/* ******************News Card design**********************/

.pdf_news_card_heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: #003da6;
  font-size: 12px;
}

.news_icon {
  box-sizing: border-box;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 10.43px;
  line-height: 10.43px;
  color: white;
  background-color: #d1c4e9;
}

.pdf_news_card {
  width: 85%;
  border-left: 2.5px solid #77a236;
  box-shadow: 0px 0.521479px 9.38661px rgba(0, 0, 0, 0.12);
}

.pdf_news_business {
  font-family: "Inter", sans-serif;
  color: #79c7f3;
  line-height: 13.39px;
  font-weight: 500;
  font-size: 9px;
}

.pdf_news_resource_link {
  color: #616f8d;
  font-size: 9px;
  font-weight: 500;
}

.pdf_news_headline {
  font-weight: 600;
  font-size: 11px;
  line-height: 18.43px;
  color: #003da6;
}

.pdf_news_btn {
  border: 0.521479px solid #dbdde0;
  border-radius: 52.1479px;
  font-weight: 500;
  line-height: 8px;
  color: #4f5d7b;
  font-size: 9px;
}

.pdf_news_desc {
  font-weight: 400;
  margin-left: 9.5px;
  font-size: 10px;
  line-height: 16.39px;
  color: #333a41;
}

.pdf_news_line_break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

/* ****************ratings scale design************** */

.pdf_ratings_scale {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #333a41;
}

/* ******************Chart design ****************** */

.pdf_chart_title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  text-transform: uppercase;
  color: #003da6;
  padding: 10px 10px 15px 10px;
}

/* *****************CMM Designs**************** */

.cmm_header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11.321px;
  line-height: 14px;
  color: #003da6;
}

.cmm_footer {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11.321px;
  line-height: 14px;
  color: #003da6;
}

/* Icons styling party profile page */

.lnr-store {
  font-size: 30px;
}

.table-cell-vertical-baseline {
  vertical-align: baseline !important;
}

/* ******************Party Profile Page****************** */
.party_profile_font {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.party_profile_name {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #313948;
}

.party_profile_section_heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #313948;
}

.party_profile_about {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #333a41;
}

.party_profile_card_heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #767f88;
}

.party_profile_card_data {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3e485c;
}

.party_profile_signatories_heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #003da6;
}

.party_profile_signatories_data {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: #3e485c;
}

.cmm_table {
  margin-bottom: 20px;
}

.table-xs {
  tr,
  td {
    padding: 0.1rem !important;
    font-size: 0.7rem !important;
  }
}

/* TRS Styles */

.trs-main-container {
  width: 80px;
  display: inline-block;
}

.trs-trust-range-active {
  text-align: center;
  padding: 15px 0px;
  min-height: 70px;
}

.trs-trust-range-inactive {
  text-align: center;
  padding: 8px 0px;
}

.benchmark-box {
  width: 60px;
  word-break: break-word;
  overflow: hidden;
}

.fixed-width-ff-value {
  min-width: 100px;
}

.jssocials {
  font-size: 1rem;
  position: fixed;
  z-index: 105;
  top: calc(50vh - 103px);
  right: 0;
}

.jssocials-share {
  width: 43px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jssocials-share-linkedin {
  background: #007bb6;
}

.jssocials-share-twitter {
  background: #00aced;
}

.jssocials-share-facebook {
  background: #3b5998;
}

.jssocials-share-email {
  background: #3490f3;
}

.jssocials-share-link {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  padding: 0.5em 0.6em;
  color: #fff;
  -webkit-transition: background 200ms ease-in-out,
    border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
}

.cover-logo {
  width: 250px;
  height: 30px;
}

.cover-title {
  border-top: 3px solid #b1cdff;
  border-bottom: 3px solid #b1cdff;
  padding: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 23px;
  /* identical to box height, or 96% */
  letter-spacing: 0.2px;
  color: #003da7;
}

.cover-sub-title {
  color: #b1cdff;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
}

.cover-sub-title-content {
  color: #003da7;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
}

.cw-h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

/* v1/profile/css */
/* .cw-btn-primary{
  background-color:#003DA6  !important;
  border: 1px solid #003DA6  !important;
  color:white;
}

.cw-btn-secondary{
  background-color:white;
  border: 1px solid #003DA6  !important;
  color: #003DA6;
}

.cw-btn-secondary:hover{
  background-color:#003DA6  !important;
  color:white;
}

.cw-btn-link{
  background-color:white;
  color:#003EFD  !important;
  
}
.cw-btn-link:hover{
  text-decoration: underline;
  color:#003DA6 !important;
} */

.cw-title {
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
  color: #1e2749;
}



.cw-description {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  color: #737b7d;
}

.cw-icon {
  font-size: 10px;
  text-align: center;
  color: #1e2749;
}

#a-tag {
  text-decoration: none;
  color: #000000;
}

.business-section:hover {
  border: 1px solid #dae6ff;
  border-radius: 8px;
  box-shadow: 5px 5px 9px -5px rgba(170, 170, 170, 0.25);
}

.cardtitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #373f41;
}

.cardtitle:hover {
  color: #1246ff;
}

.card-description {
  font-size: 15px;
  color: #373f41;
}

.card {
  grid-area: main;
}

.report-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #373f41;
}

.insights {
  border: 1.2px solid #003da6;
  background-color: #eaf1ff;
  border-radius: 8px;
  box-shadow: 5px 5px 9px -5px rgba(170, 170, 170, 0.25);
}

.cw-reverse-direction {
  flex-direction: row-reverse;
}

@media screen and (max-width: 768px) {
  .cw-reverse-direction {
    flex-direction: row;
  }
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-28 {
  font-size: 28px;
}

.fs-24 {
  font-size: 24px;
}

.bg-public-profile {
  background-color: #f9fbff;
}

.bg-public-registrations {
  background-color: #f7f8fc;
}

.bg-secondary-profile {
  background-color: #eaf1ff;
  border: 1.2px solid #003da6;
}

.bg-secondary-card {
  background-color: #eaf1ff;
}

.text-color-profile {
  color: #1246ff;
}

.text-secondary-headings {
  color: #818bad;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 10px #1246ff;
}

html {
  scroll-behavior: smooth;
}

.h-100 {
  height: 100% !important;
}

.h-100-vh {
  height: 100vh !important;
}

.newsletter form {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  flex-grow: 1;
}

.newsletter input {
  margin-right: 0.5rem !important;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $fc-primary;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: 12px;
}

.newsletter li {
  display: none;
}

.newsletter ul {
  display: none;
}

.newsletter .inputs-list {
  display: none;
}

.newsletter label {
  display: none;
}

.newsletter .hs_submit {
  position: relative;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
}

.newsletter .hs_email {
  display: inline;
}

.newsletter .hs-button {
  background-color: #003da6;
  color: white;
}

// .slick-list { height: 100vh !important; }
.slick-track {
  height: 100%;
}

.slick-prev:before,
.slick-next:before {
  display: none !important;
}

.slick-prev,
.slick-next {
  box-shadow: none !important;
  border-radius: 50% !important;
  background: transparent;
}

.slick-prev {
  left: -20px !important;
}

.slick-next {
  right: -20px !important;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #c5e9ff !important;
}

.jssocials {
  font-size: 1rem;
  position: fixed;
  z-index: 105;
  top: calc(50vh - 103px);
  right: 0;
}

.jssocials-share {
  width: 43px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jssocials-share-linkedin {
  background: #007bb6;
}

.jssocials-share-twitter {
  background: #00aced;
}

.jssocials-share-facebook {
  background: #3b5998;
}

.jssocials-share-email {
  background: #3490f3;
}

.jssocials-share-link {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  padding: 0.5em 0.6em;
  color: #fff;
  -webkit-transition: background 200ms ease-in-out,
    border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
}

.cursor-p {
  cursor: pointer !important;
}

.modal_name_color {
  color: #003da6;
}

.break-word {
  word-wrap: break-word;
}

.modal-dialog {
  box-shadow: none;
}

/*  Transition CSS Start */
.modal-backdrop {
  background-color: #33485e;
  opacity: 1 !important;
}

.modal-dialog {
  box-shadow: none;
}

/*  Transition CSS Start */
.modal-backdrop {
  /* background-color: #33485e; */
  opacity: 0.7 !important;
}

.signup-modal {
  position: absolute !important;
}

@media screen and (max-width: 768px) {
  .promotional-modal {
    left: 42%;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* your css rules for ipad portrait */
  .promotional-img {
    width: 250px !important;
    transform: rotate(318deg) !important;
    display: flex !important;
    direction: rtl !important;
    float: right !important;
    margin-left: 415px !important;
    margin-bottom: 70% !important;
  }
}

.remove-background {
  background: none;
}

@media only screen and (min-width: 820px) and (orientation: portrait) {
  /* your css rules for ipad AIR */
  .promotional-img {
    width: 250px !important;
    transform: rotate(318deg) !important;
    display: flex !important;
    direction: rtl !important;
    float: right !important;
    margin-left: 415px !important;
    margin-bottom: 70% !important;
  }
}

newsletter-top {
  top: -94px;
}

.newsletter input {
  border-radius: 4px !important;
  display: inline-block;
  vertical-align: middle;
}

.hs-button {
  border: 1px solid #003da6 !important;
  border-radius: 4px;
}

.newsletter form {
  display: flex !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.newsletter .hs_submit {
  display: inline-block;
  vertical-align: middle;
  top: 0px;
  left: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .ipad-height {
    height: auto !important;
  }
}

/* Custom CSS for reactour/tour in Crediwatch One app */
.reactour__popover div[dir="ltr"] {
  position: absolute;
  bottom: 0;
  margin-bottom: 8px;
}

.reactour__popover div[dir="ltr"] div {
  bottom: 0;
  position: fixed;
  left: 0%;
}

.reactour__popover div[dir="ltr"] div {
  bottom: 0;
  position: fixed;
  left: 0%;
}

.reactour__popover {
  padding: 16px !important;
}

.reactour__popover div.p-0 {
  width: 95% !important;
  margin-bottom: 32px !important;
}

button.css-n1avs6-Navigation:focus {
  background: red;
}

.css-gas4o7-Navigation {
  width: 37px !important;
  border-radius: 0% !important;
  margin: 0 !important;
  transform: none !important;
  transition: none !important;
  border-left: none !important;
  border-right: none !important;
}

.css-1241u7d-Navigation {
  width: 38px !important;
  border-radius: 0% !important;
  margin: 0 !important;
  transform: none !important;
  transition: none !important;
}

.css-3h4r3a-Badge {
  box-shadow: none !important;
  color: #003da6 !important;
  left: 24rem !important;
  font-weight: 600 !important;
  background: none !important;
  bottom: 1rem !important;
  right: 0;
  top: unset !important;
}

.css-3h4r3a-Badge::after {
  content: "/12";
}

.child-text-indent {
  text-indent: 30px !important;
}

.parent-text-indent {
  text-indent: 15px !important;
}

.custom-content {
  padding: 10px 10px 0px 0px;
  font-size: 18px;
  font-weight: 500;
  color: #9e9e9e;
  margin-left: 32px;
}

.fw-400 {
  font-weight: 400;
}

@media screen and (max-width: 830px) {
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
  }
}

.auth-heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #24346a;
}

.phone-input-border {
  border: 1px solid #eff1f5;
}

.step-link-style-before::before {
  display: none;
}

.step-link-style-after::after {
  display: none;
}

.footer-background-color {
  background-color: #f7fafe;
}

.login-otp-input::-webkit-inner-spin-button,
.login-otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login-otp-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 16px;
}

.login-otp-input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 16px;
}

.login-otp-input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 16px;
}

.login-otp-input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 16px;
}

@media only screen and (max-width: 820px) {
  .page-title-ipad-align {
    margin: 0px !important;
    padding: 0px !important;
  }
}

// title styles
// @media only screen and (max-width: 480px){
//   .app-page-title{
//     position: sticky;
//     top: 64px;
//     background: #fff !important;
//   }
// }

@media only screen and (max-width: 480px) {
  .app-page-title {
    z-index: 2;
  }
}

.order-card-popup-bg-color {
  background: #f9fbff !important;
}

.title-shrink {
  transition: 0.25s ease;
  width: 100%;
  height: 20px;
  /* transform: scaley(0.8); */
}

.disabled-cursor {
  cursor: not-allowed;
}

.order-report-mobile-screen {
  z-index: 3;
  height: calc(100vh - 70px) !important;
}

.order-report-selected-card {
  border: 1px solid #003da6;
}

.title-shrink .page-title-head {
  font-size: 0.88rem;
}

.title-shrink button {
  border: none !important;
}

.app-page-title-custom {
  box-shadow: 0px 10px 15px -15px #111;
  margin-left: -16px;
  margin-right: -16px;
}

.videoIcon-vertical-align-sub {
  vertical-align: sub !important;
}

.app-page-title-sticky {
  position: sticky;
  top: 64px;
  background: #fff !important;
  z-index: 10;
}

.title-shrink .page-title-icon {
  background: transparent;
}

// claimed profile

.ppp-claimed-title-green {
  border: 1px solid #67ac5d;
  border-radius: 4px;
}

.verified-badge-background-color {
  background-color: #1e2749;
}

/* 
.popover-arrow{
  background-color:#1E2749 !important;  
} 
 .bs-popover-end>.popover-arrow::after {
 color: #1E2749 !important;  
} */

.title-shrink .title-heading-section {
  display: none;
}

.btn-outline-yellow {
  border: solid 1px #f6d009;
  color: #f6d009;
  transition: 0.2s;
}

.btn-outline-yellow:hover {
  background-color: #f6d009;
}

.btn-outline-orange {
  border: solid 1px #ed8935;
  color: #ed8935;
  transition: 0.2s;
}

.btn-outline-orange:hover {
  background-color: #ed8935;
}

.btn-outline-green {
  border: solid 1px #57ae6d;
  color: #57ae6d;
  transition: 0.2s;
}

.btn-outline-green:hover {
  background-color: #57ae6d;
}

/* Styles for Tally Integration*/
.tally-integration-heading {
  font-size: 16px;
  font-weight: 600;
  color: #24346a;
}

.tally-integration-sub-heading {
  font-size: 14px;
  color: #24346a;
}

.tally-integration-company-heading {
  color: #818bad;
  font-size: 11px;
}

.tally-integration-list-item {
  font-size: 16px;
  color: #24346a;
  padding-bottom: 7px;
  font-weight: 400;
}

.fs-32 {
  font-size: 32px;
}

.fs-28 {
  font-size: 28px;
}

.fs-22 {
  font-size: 22px;
}

.fs-18 {
  font-size: 18px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-10 {
  font-size: 10px;
}

.fb {
  font-weight: bold;
}

.fw-800 {
  font-weight: 800;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-400 {
  font-weight: 400;
}

.width-100 {
  width: 100%;
}

.z-index0 {
  z-index: 0;
}

.fc-blue {
  color: $fc-primary;
}

.fc-black {
  color: $fc-secondary;
}

.fc-primary {
  color: $fc-primary;
}

.fc-secondary {
  color: $fc-secondary;
}

.fc-green {
  color: #93c889;
}

.fc-red {
  color: #dc6262;
}

.fc-orange {
  color: #e7aa50;
}

.fc-grey {
  color: #7d7c7c;
}

.fc-green {
  color: #077f8dcf;
}

.fc-pink {
  color: #a74170;
}

.fc-green1 {
  color: #b0bf40cc;
}

.fc-facebook {
  color: #395185;
}

.fc-linkedin {
  color: #0a66c2;
}

.fc-twitter {
  color: #55acee;
}

.icon-22 {
  font-size: 22px;
}

.icon-18 {
  font-size: 18px;
}

.icon-56 {
  font-size: 56px;
}

.text-uppercase {
  text-transform: uppercase;
}

.word-spacing {
  letter-spacing: 2px;
}

.cardColor {
  background-color: #fafafa;
  border: #fafafa;
  box-shadow: black;
}

.height-240 {
  max-height: 240px;
}
.height-300 {
  max-height: 300px;
  overflow-y: auto;
}
.li-dashboard {
  list-style: circle;
}
.custom-width {
  width: 300px;
}
.z-index-0 {
  z-index: 0;
}

.pad-8 {
  padding: 8px;
}

.pad-l-8 {
  padding-left: 8px;
}

.pad-r-8 {
  padding-right: 8px;
}

.pad-t-8 {
  padding-top: 8px;
}

.pad-b-8 {
  padding-bottom: 8px;
}

.pad-16 {
  padding: 16px;
}

.pad-l-16 {
  padding-left: 16px;
}

.pad-r-16 {
  padding-right: 16px;
}

.pad-t-16 {
  padding-top: 16px;
}

.pad-b-16 {
  padding-bottom: 16px;
}

@media only screen and (max-width: 421px) {
  .device-1 {
    order: 1;
  }

  .device-2 {
    order: 2;
  }

  .device-3 {
    order: 3;
  }

  .device-4 {
    order: 4;
  }

  .device-c-1 {
    order: 1;
  }

  .device-c-2 {
    order: 2;
  }

  .device-c-3 {
    order: 3;
  }

  .device-c-4 {
    order: 4;
  }

  .device-c-5 {
    order: 5;
  }

  .device-c-6 {
    order: 6;
  }

  .device-r-1 {
    order: 1;
  }

  .device-r-2 {
    order: 2;
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 35px;
  padding: 10px 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #6599e9;
  border-radius: 4px;
  background-color: #fcfcfc;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 33px;
  width: 100%;
  border: 1px solid rgb(227, 223, 223);
  background-color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.custom-landing-page-height {
  height: calc(100vh - 120px);
  padding-bottom: 16px;
}

.custom-landing-page-height #advancedSearch {
  margin: 0px 15px;
  width: 100%;
  max-width: 1080px;
  height: 45px;
}

.cursor-pointer,
.cursor-p {
  cursor: pointer;
}

.custom-inner-layout-wrapper {
  padding-bottom: 60px;
}

.scoreCard_low {
  background-color: #f6d009;
}

.scoreCard_medium {
  background-color: #ed8935;
}

.scoreCard_high {
  background-color: #cd2525;
}

.scoreCard_no {
  background-color: #57ae6d;
}

.score-card-na {
  background-color: gray;
}

/**EWS 1.1**/
.new-card-width {
  width: 100px;
}

.link-important {
  color: #0990d9 !important;
}

.max-width-card {
  max-width: 550px;
}

.new-info-font {
  font-size: 14px;
  padding: 0px 10px;
}

.expand-row-background {
  background-color: #fafcff !important;
}

.expand-row-min-height {
  min-height: 30px;
}

.risk-card-height {
  height: 32px;
}

.risk-label-height {
  min-height: 50px;
}

.risk-label-width {
  width: 300px;
}

.new_expand_na_card {
  background-color: #e2e5e9 !important;
  border: 1px solid #b3a6a6;
  text-transform: capitalize;
}

.new_risk_low_card {
  background-color: #81c784;
  padding: 0px 10px;
  text-transform: capitalize;
  color: white;
}

.new_risk_green_card {
  background-color: green;
  padding: 0px 10px;
  text-transform: capitalize;
  color: white;
}

.new_risk_medium_card {
  background-color: #e7aa50;
  padding: 0px 10px;
  text-transform: capitalize;
  color: white;
}

.new_risk_high_card {
  background-color: #dc6262;
  padding: 0px 10px;
  text-transform: capitalize;
  color: white;
}

.new_over_ride_text {
  background-color: #dc6262;
  font-size: 12px;
  padding: 0px 3px;
  height: 17px;
  color: white;
}

.new_risk_na_card {
  background-color: #e2e5e9;
  padding: 0px 10px;
  text-transform: capitalize;
}

.new_risk_na_circle {
  background-color: #e2e5e9;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  font-size: 12px;
  color: black;
}

.new_risk_low_circle {
  background-color: #81c784;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  font-size: 12px;
  color: white;
}

.new_risk_medium_circle {
  background-color: #e7aa50;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  font-size: 12px;
  color: white;
}

.new_risk_high_circle {
  background-color: #dc6262;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  font-size: 12px;
  color: white;
}

.ews-score-details-head {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
  display: inline-flex;
}

.ews-score-details-head-section {
  padding: 0 10px;
}

.ews-score-details-head-section-label {
  font-size: 1rem;
}

.ews-score-details-head-section-text {
  font-size: 1.8rem;
}

.ews-score-details-head-section-divider {
  width: 1px;
  border-left: 1px solid #d3d3d3;
  height: 3.4rem;
  margin: 2px 8px;
}

.highcharts-credits {
  display: none;
}

.h-100 {
  height: 100% !important;
}

.app-main .app-main__inner {
  padding: 16px 16px 0;
}

.app-main__inner {
  min-height: calc(100vh - 120px);
}

.app-header__logo {
  order: initial !important;
}

.app-header__mobile-menu {
  order: initial !important;
}

.bg-lightBlue {
  background-color: #e6ecf6 !important;
}

@media (max-width: 991.98px) {
  .dropdown-menu {
    width: calc(100vw - 50px) !important;
  }
}

@media only screen and (max-width: 430px) {
  .products-table-party-column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}

.fixed-card-height {
  height: 260px;
}

.grid-menu {
  margin-bottom: 0px !important;
}
.dropdown-menu.dropdown-menu-xl {
  min-width: 25rem;
}
/* party icons */

/* /cdn/assets/icons/public_listed_company.svg */

.icon-public-listed-company::before {
  content: url("/cdn/assets/icons/public_listed_company.svg") !important;
}

.icon-firm::before {
  content: url("/cdn/assets/icons/firm.svg") !important;
}

.icon-limited-liability-partnership::before {
  content: url("/cdn/assets/icons/limited_liabilit_partnership.svg") !important;
}

.icon-private-company::before {
  content: url("/cdn/assets/icons/private_company.svg") !important;
}

.icon-one-person-company::before {
  content: url("/cdn/assets/icons/one_person_company.svg") !important;
}

/* Text and background styles */

.primary-text-base {
  color: #003da6 !important;
}

.primary-text-light {
  color: #003efd !important;
}

.primary-text-dark {
  color: #001042 !important;
}

/* background colrs */
// background colors for Red Flag Monitoring start
$mail-flag-colors: "FEF9CA", "408ED3", "D8D9D9", "E7EAF1", "F7CFD3", "D5CCC8",
  "DBBFE4", "F8E5EC", "F7CEBF", "B9A5A4", "DEEDF2", "113262", "AFA2C4", "D8C396",
  "E6F1FC", "FCF2CF", "C6CAE6", "BBDEBD", "FAE1B7", "F5C342";

@each $color in $mail-flag-colors {
  $colorcode: "##{$color}";

  .mail-flag-#{$color} {
    background-color: #{$colorcode};
  }
}

// background colors for Red Flag Monitoring end

.primary-bg-base {
  background-color: #003da6 !important;
  color: white;
}

.primary-bg-light {
  background-color: #003efd !important;
  color: white;
}

.primary-bg-dark {
  background-color: #001042 !important;
  color: white;
}

/* buttons */

.cw-btn {
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  padding: 8px 10px 8px 10px;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

/* .cw-btn > .fa, .fas {
  width: 14px !important;
} */

.cw-btn-primary {
  background-color: $cw-btn-primary-500 !important;
  border: 1px solid $cw-btn-primary-500 !important;
  color: white;
}

.cw-btn-primary:disabled {
  background-color: $cw-btn-primary-200 !important;
  border: 1px solid $cw-btn-primary-200 !important;
  color: white;
}

.cw-btn-primary:hover {
  background-color: $cw-btn-primary-700 !important;
  border: 1px solid $cw-btn-primary-700 !important;
  color: white;
}

.cw-btn-secondary {
  background-color: white;
  border: 1px solid $cw-btn-secondary !important;
  color: $cw-btn-secondary;
}

.cw-btn-secondary:hover {
  background-color: $cw-btn-secondary !important;
  color: white;
}

.cw-btn-link {
  background-color: white;
  color: $cw-btn-link !important;
}

.cw-btn-link:hover {
  text-decoration: underline;
  color: $cw-btn-link !important;
}

a {
  color: #003da6;
  text-decoration: none;
}

/* card styles */

.card {
  border-radius: 8px !important;
}

.form-control {
  border-radius: 4px !important;
}

.icon-padding {
  padding: 8px 12px !important;
}

.form-group {
  margin-bottom: 0px !important;
  padding: 10px 12px !important;
  height: fit-content !important;
}

.fixed-bottom {
  z-index: 10;
}

.sa-icon.sa-success .sa-line {
  background-color: #003da6 !important;
}

.sa-icon.sa-success .sa-placeholder {
  color: #003da6 !important;
  border-color: #003da6 !important;
}

.title-shrink .page-title-icon {
  background: transparent;
}

/* styles for business integrations */
.fs-16 {
  font-size: 16px;
}

.lg-suggestion-custom-top {
  top: 64px !important;
}

.td-width {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.floating-button-container {
  position: fixed;
  bottom: 65px;
  right: 25px;
  z-index: 9999;
}

.loading-icon {
  animation: animate 2s infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

@media only screen and (min-width: 768px) {
  .floating-button-container {
    display: none;
  }
}

.input-float-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-input-float-control {
  padding: 3px 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.form-input-float-control:focus {
  outline-width: 0;
  font-weight: 400;
}

.form-input-control-placeholder {
  position: absolute;
  top: 5px;
  left: 8px;
  padding: 0;
  transition: all 300ms;
  opacity: 0.5;
  pointer-events: none;
}

.form-input-float-control:focus + .form-input-control-placeholder,
.form-input-float-control:valid + .form-input-control-placeholder {
  font-size: 80%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  background-color: white;
}

.active-menu-item {
  border-left: 3px solid #003da7;
}

.sidebar-closed {
  position: absolute;
  left: 172px;
  top: 6px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.scrollable-div {
  overflow-y: auto;
  height: calc(100vh - 100px);
}

.page-item {
  margin: 5px;
}
.page-item.active {
  background-color: var(--bs-primary);
}

.page-item:first-child .page-link {
  border: none !important;
}

.page-item:last-child .page-link {
  border: none !important;
}

.pagination-container {
  width: 100px;
}

.blur {
  filter: blur(5px);
  pointer-events: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination li {
  margin: 0 5px;
}

.pagination .no-border {
  border: none !important;
}

.border-button {
  box-sizing: border-box !important;
  padding: 10px;
  font-size: var(--bs-pagination-font-size);
  text-decoration: none;
  border: 1px solid #f1f1f1;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first {
  color: #333333;
}

.prev-btn,
.prev-btn-1 {
  border: none !important;
  background-color: white;
}

.prev-btn {
  color: #cccccc;
}

.prev-btn-1 {
  color: #003da7;
}
.active-button {
  background-color: #003da7;
}

.active-button a {
  color: white;
}
.custom-border-bottom {
  border-bottom: 1px solid #003da7;
}

// alerts table
.bg-primary-50 {
  background-color: #e6ecf6;
}
.heading-mobile-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  width: 48px;
  height: 22px;
  color: #313948;
}
.cw-mobile_icon {
  content: url("../icons/cw-mobile_icon.svg");
  margin-left: 16px;
}
.calender_icon {
  content: url("../icons/calender_icon.svg");
}

.alert_icon {
  content: url("../icons/alert_icon.svg");
  width: 16px;
  height: 16px;
}
.create-case_icon {
  content: url("../icons/create-case_icon.svg");
  width: 22px;
  height: 22px;
}
.mobile_create-case_icon{
  content: url("../icons/mobile_create-case_icon.svg");
  width: 22px;
  height: 22px;

}


.case-details_icon {
  content: url("../icons/case-details_icon.svg");
  width: 22px;
  height: 22px;
}
.mobile_case_details_icon{
  content: url("../icons/mobile_case_details_icon.svg");
  width: 22px;
  height: 22px;
}
.case-success {
  content: url("../icons/case-success.svg");

}

.details_icon {
  content: url("../icons/details_icon.svg");
  width: 22px;
  height: 22px;
}
.mobile-filter::before {
  content: url("../icons/mobile-filter.svg");
}
.back-arrow_icon {
  content: url("../icons/back-arrow_icon.svg");
  width: 20px;
  height: 20px;
}

/* Sidebar icons */
.icon-highlights::before {
  content: url("/cdn/assets/icons/sidebar/highlights.svg") !important;
}

.icon-business-details::before {
  content: url("/cdn/assets/icons/sidebar/business_details.svg") !important;
}

.icon-cases::before {
  content: url("/cdn/assets/icons/sidebar/cases.svg") !important;
}

.icon-ews::before {
  content: url("/cdn/assets/icons/sidebar/ews.svg") !important;
}

.icon-alerts::before {
  content: url("/cdn/assets/icons/sidebar/alerts.svg") !important;
}

.icon-registrations::before {
  content: url("/cdn/assets/icons/sidebar/registrations.svg") !important;
}

.icon-relationships::before {
  content: url("/cdn/assets/icons/sidebar/relationships.svg") !important;
}

.icon-financials::before {
  content: url("/cdn/assets/icons/sidebar/financials.svg") !important;
}

.icon-charges::before {
  content: url("/cdn/assets/icons/sidebar/charges.svg") !important;
}

.icon-legal::before {
  content: url("/cdn/assets/icons/sidebar/legal.svg") !important;
}

.icon-timelines::before {
  content: url("/cdn/assets/icons/sidebar/timeline.svg") !important;
}

.icon-ratings::before {
  content: url("/cdn/assets/icons/sidebar/ratings.svg") !important;
}

.icon-sanctions::before {
  content: url("/cdn/assets/icons/sidebar/sanctions.svg") !important;
}

.icon-blacklists::before {
  content: url("/cdn/assets/icons/sidebar/blacklists.svg") !important;
}

.icon-gstn-info::before {
  content: url("/cdn/assets/icons/sidebar/gstn_info.svg") !important;
}

.icon-epfo::before {
  content: url("/cdn/assets/icons/sidebar/epfo.svg") !important;
}

.icon-news::before {
  content: url("/cdn/assets/icons/sidebar/news.svg") !important;
}

.icon-contact::before {
  content: url("/cdn/assets/icons/sidebar/contact.svg") !important;
}

.icon-party::before {
  content: url("../icons/party.svg") !important;
}

.icon-portfolio::before {
  content: url("../icons/portfolio.svg") !important;
}

.icon-cases1::before {
  content: url("../icons/cases.svg") !important;
}

.icon-products::before {
  content: url("../icons/products.svg") !important;
}

.icon-alerts1::before {
  content: url("../icons/alerts.svg") !important;
}

.sign-out::before {
  content: url("../icons/signout.svg") !important;
}

@media (max-width: 768px) {
  .dropdown-align-right {
    right: 10px;
    left: auto;
  }
}

.case_Icon::before {
  content: url("../icons/case_Icon.svg") !important;
  margin-right: 10px;
}

.admin-config::before {
  content: url("../icons/admin-config.svg") !important;
}
.admin-config::before {
  content: url("../icons/admin-config.svg") !important;
}

.date::before {
  content: url("../icons/icon-date.svg") !important;
}

.date {
  display: inline-block;
  vertical-align: middle;
}

.pin {
  content: url("../icons/pin.svg") !important;
}

.pin-primary {
  content: url("../icons/pin-primary.svg") !important;
}

.case-user::before {
  content: url("../icons/case-user.svg") !important;
}
.create-case::before {
  content: url("../icons/create-case.svg") !important;
}
.expand::before {
  content: url("../icons/expand.svg") !important;
}
.case-user {
  margin-top: 5px;
}
.export {
  content: url("../icons/export.svg") !important;
}
.view-alert::before {
  content: url("../icons/view-alert.svg") !important;
}
.mobile_view-alert::before {
  content: url("../icons/mobile_view-alert.svg") !important;
}
.port-parties::before {
  content: url("../icons/port-parties.svg") !important;
}
.eye::before {
  content: url("../icons/eye.svg") !important;
}
.mobile_eye::before{
  content: url("../icons/mobile_eye.svg") !important;
}
.eye1::before {
  content: url("../icons/eye1.svg") !important;
}
//cases page
:root {
  --bs-btn-color: #15181e;
  --bs-btn-hover-color: #15181e;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #15181e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #15181e;
}
.btn-new,
.btn-closed .btn-done,
.btn-done,
.btn-escalation,
.btn-wip {
  --bs-btn-bg: #cae0b7;
  --bs-btn-border-color: #cae0b7;
  --bs-btn-hover-bg: #cae0b7;
  --bs-btn-hover-border-color: #cae0b7;
  --bs-btn-active-bg: #cae0b7;
  --bs-btn-active-border-color: #cae0b7;
  --bs-btn-disabled-bg: #cae0b7;
  --bs-btn-disabled-border-color: #cae0b7;
}
.btn-wip {
  --bs-btn-bg: #ebe4d4;
  --bs-btn-border-color: #ebe4d4;
  --bs-btn-hover-bg: #ebe4d4;
  --bs-btn-hover-border-color: #ebe4d4;
  --bs-btn-active-bg: #ebe4d4;
  --bs-btn-active-border-color: #ebe4d4;
  --bs-btn-disabled-bg: #ebe4d4;
  --bs-btn-disabled-border-color: #ebe4d4;
}
.btn-closed,
.btn-done,
.btn-escalation,
.btn-escalation_1,
.btn-escalation_2  {
  --bs-btn-bg: #e6ecf6;
  --bs-btn-border-color: #e6ecf6;
  --bs-btn-hover-bg: #e6ecf6;
  --bs-btn-hover-border-color: #e6ecf6;
  --bs-btn-active-bg: #e6ecf6;
  --bs-btn-active-border-color: #e6ecf6;
  --bs-btn-disabled-bg: #e6ecf6;
  --bs-btn-disabled-border-color: #e6ecf6;
}
.btn-review_1,
.btn-review_2 {
  --bs-btn-bg: #ffe6e6;
  --bs-btn-border-color: #ffe6e6;
  --bs-btn-hover-bg: #ffe6e6;
  --bs-btn-hover-border-color: #ffe6e6;
  --bs-btn-active-bg: #ffe6e6;
  --bs-btn-active-border-color: #ffe6e6;
  --bs-btn-disabled-bg: #ffe6e6;
  --bs-btn-disabled-border-color: #ffe6e6;
}
.btn-low {
  --bs-btn-bg: #549b18;
  --bs-btn-border-color: #549b18;
  --bs-btn-hover-bg: #549b18;
  --bs-btn-hover-border-color: #549b18;
  --bs-btn-active-bg: #549b18;
  --bs-btn-active-border-color: #549b18;
  --bs-btn-disabled-bg: #549b18;
  --bs-btn-disabled-border-color: #549b18;
}
.btn-medium {
  --bs-btn-bg: #fec900;
  --bs-btn-border-color: #fec900;
  --bs-btn-hover-bg: #fec900;
  --bs-btn-hover-border-color: #fec900;
  --bs-btn-active-bg: #fec900;
  --bs-btn-active-border-color: #fec900;
  --bs-btn-disabled-bg: #fec900;
  --bs-btn-disabled-border-color: #fec900;
}
.btn-high {
  --bs-btn-bg: #ff0000;
  --bs-btn-border-color: #ff0000;
  --bs-btn-hover-bg: #ff0000;
  --bs-btn-hover-border-color: #ff0000;
  --bs-btn-active-bg: #ff0000;
  --bs-btn-active-border-color: #ff0000;
  --bs-btn-disabled-bg: #ff0000;
  --bs-btn-disabled-border-color: #ff0000;
}
.mark {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

.low {
  color: #549b18;
}

.medium {
  color: #fec900;
}

.high {
  color: #ff0000;
}

.mark-low {
  background-color: #549b18;
}
.mark-medium {
  background-color: #fec900;
}
.mark-high {
  background-color: #ff0000;
}
.case-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
  background-color: #f8f9fa;
  border: 1px solid #ae9a6a;
}
.case-success-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: #F9F6F1;

}

.fw-600 {
  font-weight: 600;
}

// filters component

.filter_header {
  width: 188px;
  height: 38px !important;
}

.filter_icon {
  content: url("../icons/filter_icon.svg");
  width: "14px";
  height: "14px";
}
.sort-by {
  content: url("../icons/sort-by.svg");
}

.preset_icon {
  content: url("../icons/preset_icon.svg");
  width: "14px";
  height: "14px";
}
.after_clicking_radio_icon {
  content: url("../icons/after_clicking_radio_icon.svg");
}
.radio_icon {
  content: url("../icons/radio_icon.svg");
}

.filter-collapse_icon {
  content: url("../icons/filter-collapse_icon.svg");
  cursor: pointer !important;
}

.filter-collapse_mobile_icon {
  content: url("../icons/filter_collapse_mobile_icon.svg");
  cursor: pointer !important;
}
.admin-config::before {
  content: url("../icons/admin-config.svg") !important;
}

/* footer styles */
.footer-bell::before {
  content: url("../icons/bell.svg");
}
.footer-more::before {
  content: url("../icons/more.svg");
}
.footer-close::before {
  content: url("../icons/close.svg");
}
.footer-alert::before {
  content: url("../icons/footer-alerts.svg");
}
.footer-case::before {
  content: url("../icons/footer-case.svg");
}
.footer-plus::before {
  content: url("../icons/footer-plus.svg");
}
.plus::before {
  content: url("../icons/plus.svg");
}
.footer {
  height: 60px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.plus-icon {
  position: relative;
  top: -24px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.footer i.selected {
  background-color: #e6ecf6;
  transition: all 0.3s ease;
  border-bottom: 10px solid #003da7;
  margin-top: 7px;
}
/* footer styles */

/* Loader */
.loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  text-align: center;
  line-height: 1.6rem;

  .loader-container-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 $layout-spacer-x;
  }

  small {
    margin: 10px 0 0;
    display: block;
    color: $gray-600;
    font-weight: normal;
  }

  h3 {
    text-align: center;
    display: block;
  }
}

/* Loader end */

.fab {
  position: fixed;
  bottom: 40px;
  right: 35px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 1000;
}

.filter-collapse {
  position: absolute;
  top: 75px;
  right: -3px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.rotate-filter {
  transform: rotate(180deg);
}

.bg-grey {
  background-color: #f8f9fa;
}

.sort-by-white::before {
  content: url("../icons/sort-by-white.svg");
}
.edit::before {
  content: url("../icons/edit.svg");
}
.port-edit::before {
  content: url("../icons/port-edit.svg");
}
.comments::before {
  content: url("../icons/comments.svg");
}
.comments-primary::before {
  content: url("../icons/comments-primary.svg");
}
.attachments-primary::before {
  content: url("../icons/attachments-primary.svg");
}
.attachments::before {
  content: url("../icons/attachments.svg");
}
.history::before {
  content: url("../icons/history.svg");
}
.history-primary::before {
  content: url("../icons/history-primary.svg");
}
.back::before {
  content: url("../icons/back.svg");
}

.rounded-bottom {
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.icon-only-toggle {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: inherit; /* Ensures the icon inherits the text color */
}

.icon-only-toggle .sort-by-white {
  font-size: 1.25rem; /* Adjust the icon size as needed */
}

.icon-only-toggle:hover,
.icon-only-toggle:focus,
.icon-only-toggle:active,
.icon-only-toggle:visited {
  background: none !important; /* Remove background on hover, focus, active, and visited states */
  border: none !important; /* Remove border on hover, focus, active, and visited states */
  outline: none !important; /* Remove outline on focus */
  box-shadow: none !important; /* Remove box-shadow on focus */
}

.icon-only-toggle .sort-by-white:hover {
  color: #ccc; /* Optional: Change icon color on hover for better UX */
}

.dropzone-wrapper {
  width: 100%;
  border: #ced4da dashed 2px;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 230px;
  transition: all 0.2s;
}
.dropzone-wrapper:hover {
  border-color: #003da6;
}
.dropzone-wrapper > div {
  width: 100%;
  height: 100%;
  outline: none !important;
  display: flex;
  align-items: center;
}
.dropzone-wrapper.dropzone-wrapper-lg {
  height: 300px;
}
.dropzone-wrapper.dropzone-wrapper-sm {
  height: 150px;
}
.dropzone-wrapper input {
  width: 100%;
  height: 100%;
}
.dropzone-wrapper .dropzone-content {
  margin: 0 auto;
  color: #6c757d;
}
.dropzone-wrapper[aria-disabled="true"] {
  border-color: #dee2e6;
}
.dropzone-wrapper[aria-disabled="true"] .dropzone-content {
  color: #ced4da;
}
.rc-tabs {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.rc-tabs-bar,
.rc-tabs-nav-container {
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  outline: none;
  zoom: 1;
  transition: padding 0.45s;
}

.rc-tabs-ink-bar {
  margin-top: 0;
  border-radius: 12px;
  box-shadow: 0 16px 26px -10px rgba(0, 61, 166, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 61, 166, 0.2);
}

.rc-tabs-ink-bar-animated {
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.rc-tabs-tab-prev,
.rc-tabs-tab-next {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
  line-height: 36px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  position: absolute;
}

.rc-tabs-tab-prev-icon,
.rc-tabs-tab-next-icon {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: inherit;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke: 0;
  font-family: sans-serif;
}

.rc-tabs-tab-prev-icon:before,
.rc-tabs-tab-next-icon:before {
  display: block;
}

.rc-tabs-tab-btn-disabled {
  cursor: default;
  color: #495057;
}

.rc-tabs-nav-wrap {
  overflow: hidden;
}

.rc-tabs-nav {
  box-sizing: border-box;
  padding-left: 0;
  position: relative;
  margin: 0;
  float: left;
  list-style: none;
  display: inline-block;
  transform-origin: 0 0;
}

.rc-tabs-nav > div:first-child {
  z-index: 5;
  position: relative;
}

.rc-tabs-nav-animated {
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}

.rc-tabs-nav:before,
.rc-tabs-nav:after {
  display: table;
  content: " ";
}

.rc-tabs-nav:after {
  clear: both;
}

.rc-tabs-tab {
  box-sizing: border-box;
  position: relative;
  display: block;
  transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  padding: 8px 20px;
  cursor: pointer;
}

.rc-tabs-tab:hover {
  color: #003da6;
}

.rc-tabs-tab-active,
.rc-tabs-tab-active:hover {
  color: #fff;
  cursor: default;
  transform: translateZ(0);
}

.rc-tabs-tab-disabled {
  cursor: default;
  color: #495057;
}

.rc-tabs-tab-disabled:hover {
  color: #495057;
}

.rc-tabs-content {
  zoom: 1;
}

.rc-tabs-content .rc-tabs-tabpane {
  overflow: hidden;
}

.rc-tabs-content-animated {
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    margin-left 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    margin-top 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  display: -ms-flexbox;
  display: flex;
  will-change: transform;
}

.rc-tabs-content-animated .rc-tabs-tabpane {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.no-flexbox .rc-tabs-content {
  transform: none !important;
  overflow: hidden;
}

.no-csstransitions .rc-tabs-tabpane-inactive,
.no-flexbox .rc-tabs-tabpane-inactive,
.rc-tabs-content-no-animated .rc-tabs-tabpane-inactive {
  display: none;
}

.rc-tabs-bottom .rc-tabs-tab-arrow-show {
  opacity: 1;
  width: 100%;
  height: 35px;
}

.rc-tabs-top .rc-tabs-content {
  width: 100%;
}

.rc-tabs-top .rc-tabs-nav-container-scrolling {
  padding-left: 35px;
  padding-right: 35px;
}

.rc-tabs-top .rc-tabs-nav-scroll {
  width: 99999px;
}

.rc-tabs-top .rc-tabs-nav-swipe {
  position: relative;
  left: 0;
}

.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
}

.rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 0;
  padding: 8px 0;
  -ms-flex-pack: center;
  justify-content: center;
}

.rc-tabs-top .rc-tabs-nav-wrap {
  width: 100%;
  padding: 1.5rem 8px;
}

.rc-tabs-top .rc-tabs-content-animated {
  -ms-flex-direction: row;
  flex-direction: row;
}

.rc-tabs-top .rc-tabs-content-animated .rc-tabs-tabpane {
  width: 100%;
}

.rc-tabs-top .rc-tabs-tab-next {
  right: 2px;
}

.rc-tabs-top .rc-tabs-tab-next-icon:before {
  content: ">";
}

.rc-tabs-top .rc-tabs-tab-prev {
  left: 0;
}

.rc-tabs-top .rc-tabs-tab-prev-icon:before {
  content: "<";
}

.rc-tabs-top .rc-tabs-tab-prev,
.rc-tabs-top .rc-tabs-tab-next {
  margin-right: -2px;
  width: 0;
  height: 0;
  top: 0;
  text-align: center;
  opacity: 0;
  transition: width 0.3s, height 0.3s, opacity 0.3s;
}

.rc-tabs-top .rc-tabs-tab-arrow-show {
  opacity: 1;
  width: 35px;
  height: 100%;
}

.rc-tabs-top .rc-tabs-ink-bar {
  height: 35px;
  bottom: 0;
  left: 0;
  background: #003da6;
}

.rc-tabs-top .rc-tabs-tab {
  float: left;
  height: 100%;
  line-height: 1.3;
  margin-right: 0.75rem;
}

.rc-tabs-top .rc-tabs-tabpane-inactive {
  height: 0;
  overflow: visible;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  background-color: white;
  opacity: 0.5;
  outline: none !important;
  border: none !important;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.model-header-background {
  background: #f8f9fa;
}

.fs-24-mobile {
  @media (max-width: 767.98px) { 
    font-size: 24px !important;
  }
}

.text-custom{
  color:#BFA975 !important
}

.custom-select-box {
  border: none; 
  outline: none; 
  background-color: transparent; 
  padding-right:5px;
}
.Page-select-box-container{
  width:70px; 
  height: 42px; 
  padding: 8px 15px 8px 15px; 
  gap: 8px; 
  border-radius: 8px; 
  border: 1px solid transparent;
  border-color: #DDDDDD; 
  box-sizing: border-box; 
  display:flex;
  justify-content:center;
}
.mobile-only-scroll {
  height: 450px;
  overflow-y: scroll;
}

@media (min-width: 768px) {
  .mobile-only-scroll {
    height: auto;
    overflow-y: auto;
  }
}
.styled-modal .modal-content {
  border-top: none;
  border-bottom: none;
  border-radius: 10px; 
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); 
}

.no-border-header {
  border-bottom: none;
}

.no-border-footer {
  border-top: none;
}
.custom-header .close {
  position: absolute;
  right: 20px; 
  top: 15px;  
  font-size: 1.2rem; 
}
