
$fc-family: "https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap";
$fc-primary: #24346A;
$fc-secondary: #333A41;
$fc-tertiary: #333A41;

$cw-btn-primary-200: #003DA6;
$cw-btn-primary-500: #003DA6;
$cw-btn-primary-700: #003DA6;
$cw-btn-secondary: #003DA6;
$cw-btn-link: #003EFD;