// Variables

$spacer-lg: 3rem;
$spacer-sm: 1.5rem;

$layout-spacer-lg: 3rem;
$layout-spacer-x: 1.5rem;

$app-sidebar-width: 220px;
$app-sidebar-width-collapsed: 80px;

$logo-height: 20px;
$logo-width: 180px;

$app-header-height: 64px;
$font-size-xs: ($font-size-base / 1.1);



// CREDIWATCH
$logo_crediwatch: url('/cdn/assets/images/logo-dark.svg');
$logo_crediwatch_inverse: url('https://cdn.crediwatch.com/images/logo.svg');
$color-crediwatch: #003DA6;

// ADMIN
$logo_admin: url('https://cdn.crediwatch.com/images/admin.svg');
$logo_admin_inverse: url('https://cdn.crediwatch.com/images/admin.svg');
$color-admin: #8986D9;
// CMM
$logo_cmm: url('https://cdn.crediwatch.com/images/CaseManagement.svg');
$logo_cmm_inverse: url('https://cdn.crediwatch.com/images/CaseManagement.svg');
$color-cmm: #32B0E5;


// ACCOUNTS
$logo_accounts: url('https://cdn.crediwatch.com/images/Accounts.svg');
$logo_accounts_inverse: url('https://cdn.crediwatch.com/images/Accounts.svg');
$color-accounts: #7B92A8;

// DEVELOPER
$logo_developer: url('https://cdn.crediwatch.com/images/DevPortal.svg');
$logo_developer_inverse: url('https://cdn.crediwatch.com/images/DevPortal.svg');
$color-developer: #BB9471;

// MY BUSINESS
$logo_my_business: url('https://cdn.crediwatch.com/images/Business.svg');
$logo_my_business_inverse: url('https://cdn.crediwatch.com/images/Business.svg');
$color-my_business: #0075E3;

// MIS & BILLING
$logo_mis: url('https://cdn.crediwatch.com/images/Billing.svg');
$logo_mis_inverse: url('https://cdn.crediwatch.com/images/Billing.svg');
$color-mis: #DB5481;

// JOBS
$logo_jobs: url('https://cdn.crediwatch.com/images/jobs.svg');
$logo_jobs_inverse: url('https://cdn.crediwatch.com/images/jobs.svg');
$color-jobs: #6E51E6;

// REPORTING
$logo_reporting: url('https://cdn.crediwatch.com/images/MIS.svg');
$logo_reporting_inverse: url('https://cdn.crediwatch.com/images/MIS.svg');
$color-reporting: #DB5481;