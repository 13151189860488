.myaccount-button {
  height: 22px;
  border-radius: 4px;
  border: 1px solid #003DA7;
  background-color: white;
}

.myaccount-button-name {
  font-family: 'Lato', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;
  font-weight:500;
  font-size: 12px;
  color: #003DA7;
  line-height:14.4px;
  text-align: right;
}